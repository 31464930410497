<template>
  <div>
    <DashboardLayout>
      <div class="row mb-2">
        <div class="col-lg-12" v-if="!licenceStore.chatbot_entreprise">
          <div class="alert alert-warning" style="padding:10px">⚠️ Actuellement, vous ne disposez pas du module 'entreprise'. Vos leads entreprises sont cachés.</div>
        </div>
        <div class="col-lg-8">
          <h1>Entreprises</h1>
        </div>
      </div>
      <div class="card">
        <div class="row mx-2 my-1">
          <div class="col-sm-4">
            <h2>Filtres</h2>
          </div>
        </div>
        <div class="row mx-2 mb-2">
          <div class="col-sm-4">
            Entreprise
            <input class="form-control" v-model="filterEntreprise" />
          </div>
          <div class="col-sm-4">
            Contact
            <input class="form-control" v-model="filterContact" />
          </div>
          <div class="col-sm-4">
            Role
            <multiselect :close-on-select="false"
                    track-by="code"
                    :options="optionsRole"
                    v-model="activeRole"
                    label="name"
                    placeholder="Choisir un role"
                    multiple
                    :taggable="false"
                    :show-labels="false"
            >
            </multiselect>
          </div>
        </div>
      </div>
      <section id="datatable">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div
                id="DataTables_Table_0_wrapper"
                class="dataTables_wrapper dt-bootstrap5 no-footer"
              >
                <div
                  class="d-flex justify-content-between align-items-center mx-0 row"
                >
                  <div class="col-sm-12 col-md-4">
                    <div class="dataTables_length">
                      <label
                        >Afficher<select
                          class="form-select"
                          v-model="perPage"
                          style="display: inline"
                        >
                          <option
                            v-for="(show, index) in showList"
                            :value="show"
                            :key="index"
                          >
                            {{ show }}
                          </option>
                        </select>
                        résultats par page</label
                      >
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div style="text-align: right;padding-top:10px">
                      <label
                        >
                        <input
                          v-model="filterSearch"
                          class="form-control search-input"
                          placeholder="Recherche..."
                          style="
                            width: auto;
                            display: inline;
                            margin-right: 50px;
                          "
                        />
                      </label>
                      <button
                        class="btn btn-primary"
                        @click="sendReport()"
                        :disabled="emailExtracted || (!licenceStore.chatbot_entreprise && !userStore.is_leadia_admin)"
                        :class="licenceStore.chatbot_entreprise || userStore.is_leadia_admin ? '' : 'grayscale'"
                      >
                        Extraction via email
                      </button>
                    </div>
                  </div>
                </div>
                <table
                  class="datatables-basic table dataTable no-footer dtr-column"
                  role="grid"
                >
                  <thead>
                    <tr role="row">
                      <th
                        v-for="(header, index) in headers"
                        :key="index"
                        :class="{
                          sorting: header.sort,
                          sorting_asc: header.sort === 'asc',
                          sorting_desc: header.sort === 'desc',
                        }"
                        @click="sortItems(index, header.sortBy)"
                      >
                        <span>{{ header.text }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      role="row"
                      v-for="(item, index) in sortedItems"
                      :key="index"
                      class="cursor-pointer"
                    >
                      <th @click="$router.push('/entreprise/' + item.id)"
                      >
                        <div
                          class="profile-img-container d-flex align-items-center"
                        >
                          <div class="avatar bg-light-danger">
                            <div class="avatar-content">
                              {{ !item.meta.company ? "" : item.meta.company.charAt(0) }}
                            </div>
                          </div>
                          <div class="profile-title ms-1">
                            <b>{{ item.meta.company }}</b
                            ><br />
                          </div>
                        </div>
                      </th>
                      <th @click="$router.push('/entreprise/' + item.id)">
                        <span>{{
                          formatDate(item.created_at, "DD/MM/YYYY HH:mm")
                        }}</span>
                      </th>
                      <th @click="$router.push('/entreprise/' + item.id)">
                        <span class="name"
                          >{{ item.meta.firstname }}
                          {{ item.meta.lastname }}</span
                        ><br />
                        <span class="email">{{ item.meta.email }}</span>
                      </th>
                      <th @click="$router.push('/entreprise/' + item.id)">
                        <span>{{ item.meta.position }}</span>
                      </th>
                      <th @click="$router.push('/entreprise/' + item.id)">
                        <span v-html="formatPhone(item.meta.phone)"></span>
                      </th>
                      <th @click="$router.push('/entreprise/' + item.id)">
                        <span v-html="formatPhone(item.meta.campus)"></span>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-between mx-0 row">
                  <div class="col-sm-12 col-md-6">
                    <div
                      class="dataTables_info"
                      id="DataTables_Table_0_info"
                      role="status"
                      aria-live="polite"
                    >
                      Affichage de {{ offset + 1 }} à {{ end }} entrées sur
                      {{ totalItems }}
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div
                      class="dataTables_paginate paging_simple_numbers"
                      id="DataTables_Table_0_paginate"
                    >
                      <ul class="pagination">
                        <li
                          v-if="offset > 0"
                          @click="previousPage()"
                          class="paginate_button page-item previous disabled"
                          id="DataTables_Table_0_previous"
                        >
                          <a
                            aria-controls="DataTables_Table_0"
                            data-dt-idx="0"
                            tabindex="0"
                            class="page-link"
                            >&nbsp;</a
                          >
                        </li>
                        <li
                          role="presentation"
                          class="page-item"
                          :class="{ active: index == offset / perPage }"
                          v-for="(page, index) in Math.round(nbPages)"
                        >
                          <button
                            v-if="index <= 5"
                            role="menuitemradio"
                            type="button"
                            class="page-link"
                            @click="setPage(index)"
                          >
                            {{ index + 1 }}
                          </button>
                        </li>
                        <li
                          v-if="offset + perPage < totalItems"
                          @click="nextPage()"
                          class="paginate_button page-item next disabled"
                          id="DataTables_Table_0_next"
                        >
                          <a
                            aria-controls="DataTables_Table_0"
                            data-dt-idx="1"
                            tabindex="0"
                            class="page-link"
                            >&nbsp;</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  </div>
</template>

<script>
import DashboardLayout from "@/layouts/dashboard";
import { useEntrepriseStore } from "@/stores/entreprise";
import { useAccountStore } from "@/stores/account";
import { useLicenceStore } from "@/stores/licence";
import { useUserStore } from "@/stores/user";
import Multiselect from "vue-multiselect";

export default {
  name: "Entreprise",
  components: {
    DashboardLayout,
    Multiselect
  },
  data() {
    return {
      items: this.entrepriseStore.entreprises,
      headers: [
        {
          text: "Entreprise",
          value: "meta.company",
          sort: "asc",
          sortBy: "meta.company",
        },
        {
          text: "Date",
          value: "created_at",
          sort: "asc",
          sortBy: "created_at",
        },
        { text: "Contact", value: "meta.email" },
        {
          text: "Role",
          value: "meta.position",
          sort: "asc",
          sortBy: "meta.position",
        },
        { text: "Téléphone", value: "meta.phone" },
        { text: this.singularSectionName(), value: "meta.campus" },
      ],
      filterEntreprise: "",
      filterContact: "",
      filterSearch: "",
      emailExtracted: false,
      activeRole: [],
      optionsRole: []
    };
  },
  watch: {
    "entrepriseStore.entreprises": function (oldVal, newVal) {
      this.filterItems();

      var optionsRole = [];
      this.entrepriseStore.entreprises.forEach((obj) => {
        if (optionsRole.findIndex(v => v.code === obj.meta.position) == -1){
          optionsRole.push({ name: obj.meta.position, code: obj.meta.position });
        }
      });
      this.optionsRole = optionsRole;
      this.activeRole = [];
      this.filterEntreprise = "";
      this.filterContact = "";
      this.filterSearch = "";
    },
    filterEntreprise: function (newFilter, oldFilter) {
      this.filterItems();
    },
    filterContact: function (newFilter, oldFilter) {
      this.filterItems();
    },
    activeRole: function (newFilter, oldFilter) {
      this.filterItems();
    },
    filterSearch: function (newFilter, oldFilter) {
      this.filterItems();
    },
  },
  async mounted() {
    await this.entrepriseStore.getEntreprises({ vm: this });
  },
  setup() {
    const entrepriseStore = useEntrepriseStore();
    const accountStore = useAccountStore();
    const licenceStore = useLicenceStore();
    const userStore = useUserStore();
    return {
      entrepriseStore,
      accountStore,
      licenceStore,
      userStore
    };
  },
  methods: {
    singularSectionName() {
        if(this.accountStore.account.section_naming == "campus") return "Campus";
        if(this.accountStore.account.section_naming == "school") return "École";
        if(this.accountStore.account.section_naming == "center") return "Centre de formation";
        return "Campus";
    },
    filterItems() {
      let filteredItems = this.entrepriseStore.entreprises;
      this.setPage(0);

      if (this.filterEntreprise !== "") {
        filteredItems = filteredItems.filter((item) => {
          return item.meta.company
            .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .includes(this.filterEntreprise.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
        });
      }
      if (this.filterContact !== "") {
        filteredItems = filteredItems.filter((item) => {
          return (
            item.meta.firstname.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.filterContact.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
            item.meta.lastname.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.filterContact.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
          );
        });
      }
      if (this.filterSearch !== "") {
        filteredItems = filteredItems.filter((item) => {
            return item.meta.firstname && item.meta.firstname.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.filterSearch.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
            item.meta.lastname && item.meta.lastname.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.filterSearch.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
            item.meta.company.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.filterSearch.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
        });
      }
      if (this.activeRole.length > 0) {
        filteredItems = filteredItems.filter((item) => {
          return item.meta.position && this.activeRole.findIndex(v => v.code === item.meta.position) >= 0;
        });
      }
      this.items = filteredItems;
    },
    sendReport() {
      this.entrepriseStore.sendReport({
        vm: this,
      });
      this.emailExtracted = true;
    },
  },
};
</script>

<style scoped>
.dataTable {
  font-size: 0.875rem;
  font-weight: 400;
}
.name {
  font-weight: 500;
}
.email {
  font-weight: 300;
  font-size: 0.75;
}
</style>
